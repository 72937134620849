export class VesselsService {
    
    getVesselsExport() {
        return fetch('/data/vessels.json').then(res => res.json())
                .then(d => d.data)
                
    }

    getData(){
        return fetch('http://localhost:3001/dashboard/company?id=62ce793db82673d5051d0da8')
        .then(response => response.json())
        // fetch('https://api.npms.io/v2/search?q=react')
        //     .then(response => response.json())
           
    }
   

    getCustomers(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return fetch('https://www.primefaces.org/data/customers?' + queryParams).then(res => res.json())
    }
}
     