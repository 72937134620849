import React from "react";
import styled from "styled-components";
import { MdDirectionsBoatFilled } from "react-icons/md";
import { GiElectric } from "react-icons/gi";
import { AiFillHome } from "react-icons/ai";
import { TbAntennaBars5 } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import logo from "../../assets/current-direct-logo.png";
import { useStateContext } from "../../contexts/ContextProvider";
import { Link, NavLink } from "react-router-dom";
import { Dashboard } from "../Dashboard/Dashboard";
import Vessels from "../Vessels/Vessels";

import { useTranslation } from "react-i18next";
import i18n from "../../i18next";
import "./Sidebar.css";

export const links = [
  {
    title: "Overview",
    image: <AiFillHome />,
  },
  {
    title: "Vessels",
    image: <MdDirectionsBoatFilled />,
  },
  {
    title: "Energy",
    image: <GiElectric />,
  },
  {
    title: "Transactions",
    image: <TbAntennaBars5 />,
  },
  {
    title: "User settings",
    image: <FiSettings />,
  },
];

const routes = [
  {
    path: "/",
    main: () => <Dashboard />,
  },
  {
    path: "/vessels",
    main: () => <Vessels />,
  },
  // {
  //   path: "/settings",
  //   main: () => <Settings />,
  //   sidebar: () => (
  //     <p>
  //       This is your settings page. You can change your name, image, and
  //       anything else associated with your account.
  //     </p>
  //   ),
  // },
];

function Sidebar() {
  const { activeMenu, setActiveMenu } = useStateContext();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {!activeMenu && (
        <Aside>
          <button className="brand" onClick={() => setActiveMenu(true)}>
            <img src={logo} className="App-logo" alt="logo" />
          </button>
          <ul className="links-close">
            <li>
              <NavLink to="/dashboard" exact activeClassName="active">
                <AiFillHome />
              </NavLink>
            </li>
            <li>
              <NavLink to="/vessels" activeClassName="active">
                <MdDirectionsBoatFilled />
              </NavLink>
            </li>
            <li>
              <NavLink to="/energy" activeClassName="active">
                <GiElectric />
              </NavLink>
            </li>
            <li>
              <NavLink to="/transactions" activeClassName="active">
                <TbAntennaBars5 />
              </NavLink>
            </li>
            <li>
              <NavLink to="/" activeClassName="active">
                <FiSettings />
              </NavLink>
            </li>
          </ul>
        </Aside>
      )}
      {activeMenu && (
        <Aside>
          <button className="brand" onClick={() => setActiveMenu(false)}>
            <img src={logo} className="App-logo" alt="logo" />
          </button>
          <ul className="links">
            {/* {links.map((item) => (
              <li key={item.title}>
                {item.image}
                <div className="title">{item.title}</div>
              </li>
            ))} */}
            <li>
              <NavLink
                to="/dashboard"
                exact
                activeClassName="active"
                className="navlink"
              >
                <AiFillHome />
                <div className="title">{t("sidebar-overview")}</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/vessels" activeClassName="active" className="navLink">
                <MdDirectionsBoatFilled />
                <div className="title">{t("sidebar-vessels")}</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/energy" activeClassName="active">
                <GiElectric />
                <div className="title">{t("sidebar-energy")}</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/transactions" activeClassName="active">
                <TbAntennaBars5 />
                <div className="title">{t("sidebar-transactions")}</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/">
                <FiSettings />
                <div className="title">{t("sidebar-user-settings")}</div>
              </NavLink>
            </li>
          </ul>
        </Aside>
      )}
    </React.Fragment>
  );
}

const Aside = styled.aside`
  background-color: var(--menu-color);
  height: 103%;
  width: max-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
    &:hover {
      svg {
        color: var(--primary-color);
      }
    }
  }
  svg {
    color: var(--primary-color);
    font-size: 1.5rem;
  }
  .brand {
    cursor: pointer;
    background-color: var(--menu-color) ;
    border:none;
  }
  .App-logo {
    width: 40px;
    border-radius: 10px;
    background-color: var(--menu-color);
  }
  .links, .links-close {
    padding-top: 190px;
    padding-bottom: 330px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style-type: none;
    text-decoration: none;
    grid-template-columns: 1fr 12fr;
    .title{
      padding-left:20px;
      position:  relative;
      bottom: 5px;
      display: inline;
    }
    a{
      color: white;
      list-style-type: none;
    text-decoration: none;
    }
    navLink{
      list-style-type: none;
      text-decoration: none;
    }
    li {
      list-style-type: none;
      text-decoration: none;
      border-radius: 1rem;
      padding: 0.5rem;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      display: relative;
      justify-content: center;
      align-items: center;
      svg {
        color: white;
      }
      &:hover {
        box-shadow: 0 0 60px 8px var(--app-color);
        svg {
          color: var(--primary-color);
        }
      }
    }
    .active {
      // li{
      //    box-shadow: 0 0 60px 8px var(--app-color);
      // }
      svg {
        color: var(--primary-color);
        background-color: transparent;
      }
      div{
        color: var(--primary-color);
        background-color: transparent;
      }
    }
    .selected {
      box-shadow: 0 0 60px 8px var(--app-color);
      svg {
        color: var(--primary-color);
        background-color: transparent;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
      padding-right: 0.01rem;
      padding-left:0.01rem;
      padding-top:0px;
      border-radius: 0px;
      background-color: var(--app-color);
      height:max-content;
    .links-close{
      display:none;
    }
    position:fixed;
    overflow-x: visible;
    .links{
      padding-top: 100px;
    }
`;

export default Sidebar;
