import React, { useState } from "react";
import styled from "styled-components";
import { applyCardStyles } from "../Reusable";
import { BsThreeDots } from "react-icons/bs";
import ReactApexChart from "react-apexcharts";
import { BiArrowToBottom } from "react-icons/bi";
import { BsSquareFill } from "react-icons/bs";

import { useTranslation } from "react-i18next";
import i18n from "../../i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  // animations: {
  //   tension: {
  //     duration: 3000,
  //     easing: 'linear',
  //     from: 1,
  //     to: 0,
  //     loop: true
  //   }
  // },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      align: "start",
      display: true,
      text: "Total Energy Consumed",
    },
  },
};

export const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      data: [86, 314, 106, 200, 107, 555, 633, 821, 1000],
      label: "Energy Consumed",
      borderColor: "#1C8794",
      fill: true,
      radius: 5,
      pointStyle: "circle",
      backgroundColor: "#1C8794",
      tension: 0.5,
    },
  ],
};

function TotalEnergy() {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState({
    series: [
      {
        name: "Electric Vessels",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      colors: ["#1C8794"],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
    },
  });
  return (
    <Section>
      <div className="title-container">
        <div className="title">
          <h4>{t("total-energy-consumed-title")}</h4>
          <BsThreeDots />
        </div>
      </div>
      <div className="total-energy-container">
        <div className="total-energy-info">
          <div>
            <h2>783 MWh</h2>
          </div>
          <div>
            <h6 className="consumed-energy">{t("consumed-energy")}</h6>
            <h6>
              <BiArrowToBottom /> -5.3%
            </h6>
          </div>
          <div>
            <h6 className="energy">
              <BsSquareFill /> {t("energy-consumption")}
            </h6>
          </div>
        </div>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="area"
          height={350}
          width={500}
        ></ReactApexChart>
        {/* <div>
          <Line options={options} data={data} width={500} height={400} />
        </div> */}
      </div>
    </Section>
  );
}

const Section = styled.section`
  ${applyCardStyles}
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title-container {
    margin-bottom: 1rem;
    .title {
      display: flex;
      justify-content: space-between;
      svg {
        color: var(--menu-color);
        font-size: 1.3rem;
        cursor: pointer;
      }
    }
  }
  .chart {
    height: 13rem;
    .recharts-default-tooltip {
      background-color: var(--dark-background-color) !important;
      border: none !important;
      border-radius: 1rem;
      box-shadow: 0 0 60px 8px var(--primary-color);
    }
  }
  .total-energy-container {
    width: 100%;
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    justify-items: center;
  }
  .total-energy-info {
    width: max-content;
    gap: 3.5rem;
    display: grid;
    padding-top: 30px;
    h2 {
      color: var(--menu-color);
    }
    svg {
      font-size: 15px;
      width: 20px;
    }
    .consumed-energy {
      margin-bottom: 10px;
      color: #a3aed0;
    }
    h7 {
      color: var(--menu-color);
    }
    .energy {
      color: var(--menu-color);
    }
  }
`;
export default TotalEnergy;
