import React from "react";
import styled from "styled-components";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Emissions from "../Emissions/Emissions";
import Analytics from "../Analytics/Analytics";
import TotalEnergy from "../TotalEnergy/TotalEnergy";
import TotalBattery from "../TotalBattery/TotalBattery";

export const Dashboard = () => {
  // const name = props.name;
  return (
    <Section>
      <Navbar name="Overview"/>
      <div className="grid">
        <div className="row">
          <Emissions />
          <Analytics />
        </div>
        <div className="row2">
          <TotalEnergy />
          <TotalBattery />
        </div>
      </div>
      <Footer></Footer>
    </Section>
  );
};

const Section = styled.section`
  display: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--app-color1);
  .grid {
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 5rem;
    .row {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1rem;
    }
    .row2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    height: max-content;
    .grid {
      grid-template-columns: 1fr;
      padding: 1rem;
      padding-left: 0px;
      padding-right: 40px;
      height: max-content;
      .row,
      .row2 {
        /* height: max-content; */
        grid-template-columns: 1fr;
      }
    }
  }
`;

export default Dashboard;
