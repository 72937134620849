import React from 'react'
import classes from './Footer.module.css'

export const Footer = () => {
  return (
    <footer className={classes.footer}>
    <p>&copy; 2022 CDPlatCo. All Rights Reserved.</p>
    <div className={classes.inline}>
      <div className={classes.between}>The Current Direct Project</div>
      <div className={classes.between}>License</div>
      <div className={classes.between}>Terms of Use</div>
      <div className={classes.between}>Blog</div>
    </div>
  </footer>
  )
}
export default Footer;