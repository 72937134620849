import React from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import Dashboard from "./components/Dashboard/Dashboard";
import Vessels from "./components/Vessels/Vessels";
import styled from "styled-components";
import { useStateContext } from "./contexts/ContextProvider";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Transactions from "./components/Transactions/Transactions";
import Energy from "./components/Energy/Energy";

function App() {
  const { activeMenu } = useStateContext();

  return (
    <Router>
      {!activeMenu && (
        <Div>
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="dashboard">
            <Routes>
              <Route exact path="/" element={<Navigate replace to="dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/vessels" element={<Vessels />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/energy" element={<Energy />} />
            </Routes>
          </div>
        </Div>
      )}
      {activeMenu && (
        <Div>
          <div className="sidebar-open">
            <Sidebar />
          </div>
          <div className="dashboard-open">
            <Routes>
              <Route exact path="/" element={<Navigate replace to="dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/vessels" element={<Vessels />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/energy" element={<Energy />} />
            </Routes>
          </div>
        </Div>
      )}
    </Router>
  );
}

const Div = styled.div`
  display: grid;
  // grid-template-columns: 0.5fr 12fr;
  // grid-template-columns: 1fr 1fr;
  height: 100%;
  // grid-gap: 20px 50px;
  .dashboard {
    padding-left: 6%;
  }
  .sidebar {
    position: fixed;
    // height: 100%;
  }
  .sidebar-open {
    position: fixed;
  }
  .dashboard-open {
    padding-left: 13%;
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    grid-template-columns: 1fr 12fr;
    .sidebar {
    }
    .dashboard {
    }
  }
`;

export default App;

//gia statico sidebar
// .sidebar {
//   position: fixed;
//   height: 100%;
// }
// .dashboard {
//   margin-left: 45px;
// }
