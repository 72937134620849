import React from "react";
import styled from "styled-components";
import classes from "./Navbar.module.css";
import { AiOutlineUser } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import { RiNotification3Line } from "react-icons/ri";
import { BsFillMoonFill } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { MdFilterList } from "react-icons/md";

import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import i18n from "../../i18next";

let language = "en";
i18n.changeLanguage('en');
let firstTime = 1;

export const Navbar = ({name}) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    
    if (language === "en" && firstTime === 1) {
      i18n.changeLanguage("gr");
      language = "gr";
      firstTime += 1;
    } 
    if (language === "en") {
      i18n.changeLanguage("en");
      language = "gr";
    }else {
      i18n.changeLanguage("gr");
      language = "en";
    }
  };

  const hovertime = () => {
    console.log("hover");
  };

  return (
    <Nav>
      <div>
        <h2>{t('navbar-title')}</h2>
        <h2 className={classes["pages-title"]}>{name}</h2>
      </div>
      <div className="timeline">
        <div className={classes.padding}>
          <div className={classes["search-form"]}>
            <input
              type="search"
              value=""
              placeholder={t('navbar-search')}
              className={classes["search-input"]}
            />
            <button type="submit" className={classes["search-button"]}>
              <AiOutlineSearch className={classes.svg} />
            </button>
          </div>
        </div>
        <div>
          <form className={classes["filter-form"]}>
            <input
              type="search"
              value=""
              placeholder={t('navbar-filter')}
              className={classes["filter-input"]}
            />
            <button type="submit" className={classes["search-button"]}>
              <MdFilterList className={classes.svg} />
            </button>
          </form>
        </div>
        <div className={classes.icons}>
          <MdLanguage onClick={changeLanguage} />
          <BsFillMoonFill onMouseEnter={hovertime} />
          <FaBook />
          <RiNotification3Line />
          <AiOutlineUser />
        </div>
      </div>
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  grid-template-columns: 1fr 12fr;
  color: black;
  margin: 2rem;
  .timeline {
    background-color: white;
    border-radius: 40px;
    width: max-content;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    margin-bottom: 0;
    .timeline {
      gap: 2rem;
      width: 70%;
      margin-left: 10rem;
      justify-content: end;
    }
  }
`;

export default Navbar;
