import React from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
// import 'leaflet/dist/leaflet.css'
import styled from "styled-components";
import { applyCardStyles } from "../Reusable";
import "./Maps.css";

const Maps = () => {
  const position1 = [51.949, 4.14];
  const position2 = [51.949, 4.17];
  const position3 = [51.939, 4.19];
  return (
    <Section>
      <h3>Maps</h3>
      <MapContainer
        className="leaf-container"
        center={position1}
        zoom={13}
        scrollWheelZoom={true}
      >
        <TileLayer
          url='https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        />
        <Marker position={position1}>
          <Popup>Voyage</Popup>
        </Marker>
        <Marker position={position2}>
          <Popup>Odyssey</Popup>
        </Marker>
        <Marker position={position3}>
          <Popup>Eiltank</Popup>
        </Marker>
      </MapContainer>
    </Section>
  );
};

const Section = styled.section`
  ${applyCardStyles}
  color:black;
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    .title {
    }
  }

  .total-battery-info {
    width: max-content;
    gap: 3.5rem;
    display: grid;
    padding-top: 30px;
    h2 {
      color: var(--menu-color);
    }
  }

  .f32 .flag {
    vertical-align: middle;
    margin: -8px 0;
  }

  progress {
    width: 100%;
  }

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .title-container {
      flex-direction: column;
      gap: 0.5rem;
      .filters {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
`;
export default Maps;
