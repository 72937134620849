import React, { useState } from "react";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";
import { applyCardStyles } from "../Reusable";
import music1 from "../../assets/battery.jpeg";
import music2 from "../../assets/battery.jpeg";
import music3 from "../../assets/battery.jpeg";
import music4 from "../../assets/battery.jpeg";
import music5 from "../../assets/battery.jpeg";
import music6 from "../../assets/battery.jpeg";

import { useTranslation } from "react-i18next";
import i18n from "../../i18next";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      align: "start",
      display: true,
      text: 'Total Battery Swaps',
    },
  },
  interaction: {
    mode: 'index' ,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["JUL 21","AUG 21","SEP 21","OCT 21","NOV 21","DEC 21","JAN 22","FEB 22"];

const data = {
  labels,
  datasets: [
    {
      label: 'Swaps',
      data: [21, 22, 10, 28, 16, 21, 13, 30],
      backgroundColor: '#1C8794',
      barThickness : 20,
      // borderSkipped: 'start',
      // borderRadius : 50
    }
  ],
};

function TotalBatterySwaps() {
  const { t, i18n } = useTranslation();

  const music = [
    {
      title: "Battery1",
      plays: 64,
      image: music2,
    },
    {
      title: "Battery2",
      plays: 124,
      image: music1,
    },
    {
      title: "Battery3",
      plays: 500,
      image: music3,
    },
    {
      title: "Battery4",
      plays: 238,
      image: music4,
    },
    {
      title: "Battery5",
      plays: 334,
      image: music5,
    },
    {
      title: "Battery6",
      plays: 25,
      image: music6,
    },
  ];
  //edw einai ta requirements gia ta apex charts
  const [state, setState] = useState({
    series: [
      {
        data: [21, 22, 10, 28, 16, 21, 13, 30],
      },
    ],
    options: {
      colors: ["#1C8794"],
      chart: {
        height: 350,
        type: "bar",
        //edw einai gia ta emfanisei tis leitourgies toy chart
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true
          },
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          ["JUL 21"],
          ["AUG 21"],
          ["SEP 21"],
          ["OCT 21"],
          ["NOV 21"],
          ["DEC 21"],
          ["JAN 22"],
          ["FEB 22"],
        ],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
    },
  });
  return (
    <Section>
      <div className="title-container">
        <div className="title">
          <h4>{t('total-battery-swaps-title')}</h4>
        </div>
      </div>
      <div className="total-battery-container">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={350}
          width={650}
        />
        {/* <div style={{ width: "500px",height: "0px", margin: "10px"}}>
        <Bar options={options} data={data} />
        </div> */}
        
      </div>
    </Section>
  );
}

const Section = styled.section`
  ${applyCardStyles}
  color:black;
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    .title {
    }
    .filters {
      display: flex;
      align-items: center;
      gap: 3rem;
      color: var(--menu-color);
      button {
        background-color: var(--app-color);
        color: var(--menu-color);
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 0.8rem;
        cursor: pointer;
        font-weight: bolder;
      }
    }
  }
  .musics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .music {
      border-bottom: 0.1rem solid #242424;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .details {
        display: flex;
        gap: 1rem;
        .image {
          img {
            height: 2.5rem;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          h6 {
            font-weight: 100;
          }
        }
      }
    }
  }
  .total-battery-container{
    width: 100%;
    display:grid;
    // grid-template-columns: 0.3fr 1fr;
    justify-items:center;
  }
  .total-battery-info{
    width: max-content;
    gap: 3.5rem;
    display: grid;
    padding-top: 30px;
      h2{ 
        color: var(--menu-color);
      }
  }

  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .title-container {
      flex-direction: column;
      gap: 0.5rem;
      .filters {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
    .musics {
      grid-template-columns: 1fr;
    }
  }
`;
export default TotalBatterySwaps;
