import React, { useState, useEffect, useRef } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import styled from "styled-components";
import { applyCardStyles } from "../Reusable";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Slider } from "primereact/slider";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { VesselsService } from "../../service/VesselsService";
import "./FlagTable.css";
import { VesselsHook } from "../../service/VesselsHook";

const FlagTable = () => {
  const [customers2, setCustomers2] = useState();

  const [filters2, setFilters2] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [globalFilterValue2, setGlobalFilterValue2] = useState("");

  const [loading2, setLoading2] = useState(true);
 

  const dataService = new VesselsService(); // gia dokimh htan
  const exportService = new VesselsService();

 
  useEffect(() => {
    exportService.getVesselsExport().then((data) => {
      setCustomers2(data);
      setLoading2(false);
      setUserData(data)
    })
  }, []);
  useEffect(() => {
    dataService.getData().then((data) => {
      console.log(data)
    })
  }, []); 

  


  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2["global"].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value)
  };

  const dt = useRef(null);
  let vessels_names = [];

  //edw pairnoume ta data apo nested json gia to pdf
  const List = ({ customers2 }) => (
    <li>
      {customers2.map((vessel, i) => (
        // <Skill key={i} skill={skill} />
        // console.log(vessel.country.name)
        vessels_names.push(vessel.country.name)
      ))}
    </li>
  );

  const cols = [
    { field: "name", header: "Name" },
    { field: `${'country'}`, header: "Flag" },
    // { field: "photo", header: "Photo" }
  ];


  //edw ginetai to export pdf
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));


  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, customers2);
        doc.save("vessels.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(customers2);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "vessels");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  
  const [userData, setUserData] = useState([]); //thelei useState dioti thelei keno array sthn arxh kai meta otan tha kalesoume ta dummy data tha gemisei o pinakas 
  //an den uparxei to useState tote ta data einai undefined
  const headers= [
    {label: 'Name', key: 'name'},
    {label: 'Flag', key: 'country.name'},
    // {label: 'Photo', key: 'photo'},
  ];

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue2}
            onChange={onGlobalFilterChange2}
            placeholder="Keyword Search"
          />
          <CSVLink headers={headers}
                    data={userData}
                    filename="Vessels"
                    >
            <Button
              type="button"
              icon="pi pi-file"
              className="mr-2 ml-2"
              placeholder="CSV"
            />
          </CSVLink>

          {/* <Button
            type="button"
            icon="pi pi-file-excel"
            onClick={exportExcel}
            className="p-button-success mr-2"
            data-pr-tooltip="XLS"
          />
          <Button
            type="button"
            icon="pi pi-file-pdf"
            onClick={exportPdf}
            className="p-button-warning mr-2"
            data-pr-tooltip="PDF"
          /> */}
        </span>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img
          alt="flag"
          src={rowData.country.image}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${rowData.country.code}`}
          width={30}
        />
        <span className="image-text">{rowData.country.name}</span>
      </React.Fragment>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img
          alt={rowData.name}
          src={rowData.photo}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          width={100}
          height={80}
          style={{ verticalAlign: "middle" }}
        />
      </React.Fragment>
    );
  };

  const header2 = renderHeader2();

  const [selectedProduct1, setSelectedProduct1] = useState(null);

  return (
    <Section>
      <div className="datatable-filter-demo">
        <div className="card">
          <h3>Vessels</h3>
          {/* <List customers2={userData}></List>
          <div>{vessels_names}</div> */}
          <DataTable
            value={customers2}
            paginator
            className="p-datatable-customers"
            rows={7}
            selectionMode="single"
            // selectOnEdit={{color: 'black'}}
            // onRowSelect={{color:'black'}}
            selection={selectedProduct1}
            onSelectionChange={(e) => setSelectedProduct1(e.value)}
            dataKey="id"
            responsiveLayout="scroll"
            filters={filters2}
            loading={loading2}
            responsiveLayout="scroll"
            // classNames="tableColor"
            globalFilterFields={[
              "name",
              "country.name",
              "representative.image",
            ]}
            header={header2}
            emptyMessage="No customers found."
          >
            <Column
              field="name"
              header="Name"
              sortable
              style={{ minWidth: "12rem" }}
            />
            <Column
              field="country.name"
              header="Flag"
              sortable
              style={{ minWidth: "12rem" }}
              body={countryBodyTemplate}
            />
            <Column header="Photo" body={representativeBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </Section>
  );
};

const Section = styled.section`
  ${applyCardStyles}
  color:black;
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    .title {
    }
  }

  .total-battery-info {
    width: max-content;
    gap: 3.5rem;
    display: grid;
    padding-top: 30px;
    h2 {
      color: var(--menu-color);
    }
  }

  .tableColor{
      color: #98b99b;
    
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .title-container {
      flex-direction: column;
      gap: 0.5rem;
      .filters {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
`;

export default FlagTable;


// {
//   "id": 1001,
//   "name": "EMPIRE STATE",
//   "country": {
//       "name": "USA",
//       "code": "us",
//       "image": "https://flagcdn.com/w20/us.png"
//   },
//   "photo": "/images/EMPIRE_STATE.jpeg"
// },

// {
//   "id": 1003,
//   "name": "NOOR1",
//   "country": {
//       "name": "Greece",
//       "code": "gr",
//       "image": "https://flagcdn.com/w20/gr.png"
//   },
//   "photo": "/images/NOOR1.jpeg"
// },