import React from "react";
import styled from "styled-components";
import { applyCardStyles } from "../Reusable";
import { BiEuro} from "react-icons/bi";
import { MdDirectionsBoatFilled } from "react-icons/md";
import { MdBarChart } from "react-icons/md";
import { GiElectric } from "react-icons/gi";
import { BsThreeDots } from "react-icons/bs";

import { useTranslation } from "react-i18next";
import i18n from "../../i18next";

function StateOfCharge() {
  const { t, i18n } = useTranslation();

  const analyticsData = [
    {
      name: "State of Charge",
      amount: "66%",
      icon: <MdBarChart/>
    },
    {
      name: "Amount of Energy",
      amount: "18MWh",
      icon: <GiElectric/>
    },
  ];
  return (
    <Section>
      <div className="title-container">
        <div className="title">
          <h4>{t('analytics-title')}</h4>
        </div>
       <div className="dots">
       <BsThreeDots />
       </div>
      </div>
      <div className="earnings">
        {analyticsData.map(({ name, amount, icon }) => {
          return (
            <div className="earning" key={amount}>
              <div className="data">
              <div>
                {icon}
              </div>
               <div className="header-five">
               <h5>{name}</h5>
               <h5 className="amount"> {amount}</h5>
               </div>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const Section = styled.section`
  ${applyCardStyles}
  color: black;
  display: flex;
  flex-direction: column;
  .title-container {
    display: flex;
    justify-content: space-between;
    .title {
      h1 {
        font-size: 2rem;
        letter-spacing: 0.2rem;
      }
    }
    .more {
      color: var(--menu-color);
    }
  }
  .earnings {
    width: 100%;
    gap: 3.5rem;
    display: grid;
    padding-top: 100px;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    .earning {
      .data {
        display: flex;
        gap: 1rem;
        .header-five {
          font-size: 20px;
          color: #A3AED0
        }
        .amount{
          color: var(--menu-color);
          padding-top: 8px;
        }
        svg{
          color: var(--menu-color);
          background-color: var(--app-color1);
          font-size: 50px;
          padding: 5px;
          border-radius:30px;
        }
      }
      progress {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        &::-webkit-progress-bar {
          border-radius: 1rem;
          height: 0.15rem;
        }
        &::-webkit-progress-value {
          border-radius: 1rem;
          background-color: orange;
        }
      }
      .cleared {
        &::-webkit-progress-value {
          background-color: var(--primary-color);
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    height: 100%;
    .title-container {
      flex-direction: column;
      text-align: center;
    }
    .earnings {
    }
  }
`;
export default StateOfCharge;