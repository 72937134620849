import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { applyCardStyles } from "../Reusable";
import ReactApexChart from "react-apexcharts";

import { BiArrowFromBottom } from "react-icons/bi";
import { BsSquareFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import i18n from "../../i18next";
import { dashboard24HoursPerformanceChart } from "../variables/chart.js";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  animations: {
    tension: {
      duration: 3000,
      easing: 'linear',
      from: 1,
      to: 0,
      loop: true
    }
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      align: "start",
      display: true,
      text: "Emissions",
    },
  },
};

export const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  datasets: [
    {
      data: [86, 314, 106, 200, 107, 555, 633, 821, 1000],
      label: "Electric Vessels",
      borderColor: "#1C8794",
      fill: true,
      radius: 5,
      pointStyle: 'rect',
      backgroundColor	: "#1C8794",
      tension : 3

    },
    {
      data: [582, 650, 311, 402, 335, 809, 947, 1402, 1700],
      label: "Conventional Vessels",
      borderColor: "#f58f29",
      fill: false,
      radius: 5,
      pointStyle: 'circle',
      backgroundColor	: "#f58f29",
    },
  ],
};


function Emissions() {

  const { t, i18n } = useTranslation();

  const sliderData = [
    {
      title: "Example1",
      serviceName: "Sound Cloud",
    },
    {
      title: "Example2",
      serviceName: "Spotify",
    },
    {
      title: "Example3",
      serviceName: "Youtube Music",
    },
    {
      title: "Example4",
      serviceName: "Apple Music",
    },
  ];

  const [state, setState] = useState({
    series: [
      {
        name: "Electric Vessels",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      colors: ["#1C8794"],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
    },
  });

  return (
    <Section>
      <div className="title-container">
        <div className="title">
          <h4>
            CO<sub>2</sub> {t("emissions-title")}
          </h4>
        </div>
        {/* <div className="slider">
          <div className="services">
            {sliderData.map(({ title, serviceName }) => {
              return (
                <div className="service" key={serviceName}>
                  <h1>{title}</h1>
                  <h6>{serviceName}</h6>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
      <div className="emissions-container">
        <div className="emissions-info">
          <div>
            <h2>138013 kg</h2>
          </div>
          <div>
            <h6 className="total-emissions">{t("total-emissions")}</h6>
            <h6>
              <BiArrowFromBottom /> +2.45%
            </h6>
          </div>
          <div>
            <h6 className="E-vessels">
              <BsSquareFill />
              {t("electric-vessels")}
            </h6>
            <h6 className="C-vessels">
              <BsSquareFill /> {t("conventional-vessels")}
            </h6>
          </div>
        </div>
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="area"
          height={350}
          width={600}
        ></ReactApexChart>
  
        {/* <div>
          <Line options={options} data={data} width={500} height={400} />
        </div> */}
        
      </div>
    </Section>
  );
}

const Section = styled.section`
  ${applyCardStyles}
  color:black;
  .title-container {
    display: flex;
    justify-content: space-between;
    .title {
      h1 {
        font-size: 2rem;
        letter-spacing: 0.2rem;
      }
    }
    .slider {
      .services {
        display: flex;
        gap: 1rem;
        .service {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.6rem;
          min-width: 5rem;
          img {
            height: 2rem;
          }
        }
      }
    }
  }
  .emissions-container {
    width: 100%;
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    justify-items: center;
  }
  .emissions-info {
    width: max-content;
    gap: 3.5rem;
    display: grid;
    padding-top: 30px;
    h2 {
      color: var(--menu-color);
    }
    svg {
      font-size: 15px;
      width: 20px;
    }
    .total-emissions {
      margin-bottom: 10px;
      color: #a3aed0;
    }
    h7 {
      color: #f58f29;
      svg {
      }
    }
    .E-vessels {
      color: var(--menu-color);
    }
    .C-vessels {
      color: #f58f29;
    }
  }
  .chart {
    height: 10rem;
    .recharts-default-tooltip {
      background-color: var(--dark-background-color) !important;
      border: none !important;
      border-radius: 1rem;
      box-shadow: 0 0 60px 8px var(--primary-color);
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    height: 100%;
    .title-container {
      flex-direction: column;
      gap: 0.5rem;
      .title {
        text-align: center;
      }
      .slider {
        .services {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .service {
            gap: 0.5rem;
            min-width: 1rem;
          }
        }
      }
    }
  }
`;
export default Emissions;
