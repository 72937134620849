import React, {createContext, useContext, useState} from "react";

const StateContext = createContext();

export const ContextProvider =  ({children}) => {
    const [activeMenu, setActiveMenu] = useState(false);

    return(
        <StateContext.Provider 
        value={{
            activeMenu: activeMenu,
            setActiveMenu: setActiveMenu,
        }}>
            {children}
        </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);